<script lang="ts" setup>
import {useAuthStore} from "~/stores/authUser";

const localePath = useLocalePath();
const authStore = useAuthStore();

const isAdmin = authStore.isAdmin;
const isBackOffice = authStore.isBackOffice;

const {t} = useI18n({
  useScope: "global",
});

const menuItems = computed(() => {
  const items = [
    {
      label: 'Users',
      to: localePath('/admin/users'),
    },
    {
      label: 'Messages Translation',
      to: localePath('/admin/i18n/messages'),
    },

  ]
  if (isAdmin) {
    items.push({
      label: 'Landing Content',
      children: [
        {
          label: 'Cars',
          to: localePath('/admin/content/cars'),
        },
        {
          label: 'Faq',
          to: localePath('/admin/content/faq'),
        },
        {
          label: 'Companies',
          to: localePath('/admin/content/company'),
        },
        {
          label: 'Reviews',
          to: localePath('/admin/content/reviews'),
        }
      ]
    })
  }

  items.push({
    label: t('nav.admin.tools'),
    children: [
      {
        label: t('nav.admin.tools.checkVin'),
        to: localePath('/admin/tools/check-vin'),
      },
    ]
  })

  return items
})

const menuItemsMobile = computed(() => {
  const menu = [...menuItems.value];

  menu.push({
    label: t('nav.user.links.profile'),
    to: localePath('dealer-profile'),
  })
  if (authStore.isAdmin) {
    menu.push({
      label: t('nav.user.links.admin'),
      to: localePath('admin'),
    })
    menu.push({
      label: t('nav.user.links.dealer'),
      to: localePath('dealer-cars'),
    })
  }

  menu.push({
    label: t('nav.user.links.logout'),
    itemProps: {
      'data-test': 'logout',
      onClick: () => authStore.logout(),
    }
  })

  return menu
})

</script>

<template>
  <div class="admin">
    <div class="admin__header-wrapper">
      <ThemeHeader
        logo-to="/admin/users"
        logo-path="/img/logo-default.svg"
        :menu-items="menuItems"
        :menu-items-mobile="menuItemsMobile"
      >
        <template #right>
          <AppLangSwitcher />
          <AppNotificationBell />
          <AppUserAuth />
        </template>
        <template #mobile-right>
          <AppLangSwitcher />
          <AppNotificationBell />
        </template>
      </ThemeHeader>
    </div>

    <div class="admin__content-wrapper">
      <div class="admin__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px;

  &__header-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }

  &__content {
    max-width: 1200px;
    padding: 30px 30px;
    flex: 1;
  }
}

.admin__content-wrapper {
  display: flex;
  justify-content: center;
}
</style>
